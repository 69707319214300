import React, { forwardRef, useState } from "react";
import {
  BoxProps,
  HStack,
  Heading,
  Text,
  Tooltip,
  IconButton,
  Circle,
} from "@chakra-ui/react";
import Avatar from "boring-avatars";

import { Appbar } from "../../../components/Appbar";
import { useSupportTicket } from "../../../data/support/useSupportTicket";
import { ChevronDownIcon, InfoIcon } from "../../../constants/icons";
import { FiUsers, FiUserPlus } from "react-icons/fi";
import { useSupportUsers } from "../../../data/support/useSupportUsers";
import { useAgentAssignment } from "../../../mutations/support/useAgentAssignment";

import { getCountryFromPhoneNumber } from "../../../utils/countryCodes";
import { Dropdown } from "../../../components/Dropdown/index"
import { getTimeAgo } from "../../../components/InfiniteTable";
import { isDashboardUserOnline } from "../../../utils";

interface ChatHeaderProps extends BoxProps {
  flowId: string;

  sessionId: string;

  onDetailsClick?: () => void;
}

export const ChatHeader = forwardRef<HTMLDivElement, ChatHeaderProps>(
  ({ flowId, sessionId, onDetailsClick, ...props }, ref) => {
    const [assignmentLoading, setAssignmentLoading] = useState<
      "agent" | "team" | null
    >(null);

    const { isLoading, data } = useSupportTicket(sessionId);
    const { data: supportUsers } = useSupportUsers(flowId);

    let phoneNumber = "+" + data?.conversation_id;
    let country = phoneNumber ? getCountryFromPhoneNumber(phoneNumber) : null;
    let countryFlag = country?.emoji;
    let countryName = country?.name;

    const getTeams = () => {
      const teams = [];
      (supportUsers ?? []).map((user) => teams.push(user.groups));
      return [...new Set(teams.flat())];
    };

    const handleAgentAssignment = useAgentAssignment({
      flowId,
      refetchKey: ["support_ticket", sessionId],
    });

    if (isLoading) return <Appbar ref={ref} {...props} />;

    return (
      <Appbar
        ref={ref}
        showHeader={false}
        borderBottomWidth="thin"
        boxShadow="sm"
      >
        <HStack h="16" direction="row" justify="space-between" cursor="pointer">
          <Heading size="md" fontWeight="medium">
            {data && data.name} (
            <Tooltip label={countryName}>{countryFlag}</Tooltip> {phoneNumber})
            <IconButton
              variant="ghost"
              size="sm"
              icon={<InfoIcon />}
              aria-label={"info-icon"}
              onClick={() => onDetailsClick?.()}
            />
          </Heading>
          <HStack>
            <Text className="text-gray-600">
              {data && data.agent ? "Assigned to" : "Assign to"}
            </Text>
            <Dropdown
              key={`select_teams`}
              variant="tertiary"
              isLoading={
                assignmentLoading === "team" && handleAgentAssignment.isLoading
              }
              options={getTeams().filter((team) => team !== "")}
              leftIcon={<FiUsers />}
              rightIcon={<ChevronDownIcon />}
              multiSelect={false}
              defaultSelected={data && data.agent ? data.agent.groups : []}
              onChange={(tags) => {
                if (data && data.agent && data.agent.groups.includes(tags[0]))
                  return;

                if (tags.length !== 0) {
                  setAssignmentLoading("team");
                  handleAgentAssignment.mutate({
                    sessionId,
                    agentOrTeam: tags[0],
                    onDone: () => setAssignmentLoading(null),
                  });
                }
              }}
              helperText="No teams to choose from."
            >
              <Text textAlign="left" w={24} isTruncated>
                {data && data.agent
                  ? data.agent.groups.length === 0
                    ? "No Team"
                    : data.agent.groups[0] === ""
                    ? "No Team"
                    : data.agent.groups[0]
                  : "No Team"}
              </Text>
            </Dropdown>
            <Dropdown
              key={`select_members`}
              variant="tertiary"
              isLoading={
                assignmentLoading === "agent" && handleAgentAssignment.isLoading
              }
              options={(supportUsers ?? []).map((user) => user.email)}
              defaultSelected={data && data.agent ? [data.agent.email] : []}
              leftIcon={
                data && data.agent ? (
                  <Avatar variant="beam" size={22} name={data.agent.email} />
                ) : (
                  <FiUserPlus />
                )
              }
              multiSelect={false}
              rightIcon={<ChevronDownIcon />}
              onChange={(tags) => {
                if (data && data.agent && data.agent.email === tags[0]) return;

                if (tags.length !== 0) {
                  const selectedSupportUser = (supportUsers ?? []).filter(
                    (user) => user.email === tags[0]
                  );

                  if (selectedSupportUser.length !== 0) {
                    setAssignmentLoading("agent");
                    handleAgentAssignment.mutate({
                      sessionId,
                      agentOrTeam: selectedSupportUser[0],
                      onDone: () => setAssignmentLoading(null),
                    });
                  }
                }
              }}
              helperText="No agents to choose from."
              renderOptionTrailing={(userId, _) => {
                const userList = supportUsers.filter(
                  (user) => user.email === userId
                );
                if (userList.length === 0) return <></>;
                const user = userList[0];
                return (
                  <Tooltip
                    label={
                      user.last_online && getTimeAgo(user.last_online, true)
                    }
                    placement="right"
                    offset={[0, 24]}
                  >
                    <Circle
                      bg={
                        isDashboardUserOnline(user.last_online) && "green.500"
                      }
                      borderWidth="thin"
                      borderColor={
                        isDashboardUserOnline(user.last_online)
                          ? "green.500"
                          : "gray.400"
                      }
                      size=".5rem"
                    />
                  </Tooltip>
                );
              }}
            >
              <Text textAlign="left" w={24} isTruncated>
                {data && data.agent ? data.agent.email : "Agent"}
              </Text>
            </Dropdown>
          </HStack>
        </HStack>
      </Appbar>
    );
  }
);
