import React, { useEffect, useState, useCallback } from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Button,
  Radio,
  RadioGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Text,
  Input,
  HStack,
  Stack,
  Select,
  Checkbox,
  useToast,
  FormErrorMessage,
  Heading,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "../../constants/icons";
import { createNewUser, getOrganizations, getUsers, organization, Users } from "../../api/go_live";
import { colorSystem } from "../../theme";

// Define the SelectedUser interface
interface SelectedUser {
  userId: string;
  username: string;
}

// Update the AssignmentConfig interface
interface AssignmentConfig {
  selectedOrganization: string;
  organizationId?: number;
  selectedUsers: SelectedUser[]; // Changed from string[] to SelectedUser[]
  newUser?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

interface AssignmentProps {
  assignmentConfig: AssignmentConfig;
  setAssignmentConfig: React.Dispatch<React.SetStateAction<AssignmentConfig>>;
}

const Assignment: React.FC<AssignmentProps> = ({ assignmentConfig, setAssignmentConfig }) => {
  const [organizations, setOrganizations] = useState<organization[]>([]);
  const [users, setUsers] = useState<Users[]>([]);
  const [userType, setUserType] = useState<"existing" | "new">("existing");
  const [newUser, setNewUser] = useState({ firstName: "", lastName: "", email: "" });
  const [formErrors, setFormErrors] = useState({ firstName: "", lastName: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getOrganizations();
      setOrganizations(response.OrganizationData);
    } catch (error) {
      console.error("Failed to fetch organizations", error);
      setOrganizations([]);
    }
  }, []);

  const fetchUsersForSelectedOrg = useCallback(async () => {
    if (userType === "existing" && assignmentConfig.selectedOrganization) {
      const org = organizations.find((o) => o.name === assignmentConfig.selectedOrganization);
      if (org) {
        try {
          const response = await getUsers(org.id);
          setUsers(response.usersData);
          console.log("response.users --> ", response.usersData);
        } catch (error) {
          console.error(`Failed to fetch users for organization ${assignmentConfig.selectedOrganization}`, error);
          setUsers([]);
        }
      }
    } else {
      setUsers([]);
    }
  }, [assignmentConfig.selectedOrganization, organizations, userType]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  useEffect(() => {
    fetchUsersForSelectedOrg();
  }, [fetchUsersForSelectedOrg]);

  const handleOrganizationChange = (orgName: string, orgId: number) => {
    setAssignmentConfig((prev) => ({
      ...prev,
      selectedOrganization: orgName,
      organizationId: orgId,
      selectedUsers: [],
    }));
  };

  const handleUserChange = (selectedUsers: SelectedUser[]) => {
    setAssignmentConfig((prev) => ({
      ...prev,
      selectedUsers,
    }));
  };

  const handleNewUserChange = (field: keyof typeof newUser, value: string) => {
    setNewUser((prev) => ({ ...prev, [field]: value }));
    // Clear the error when the user starts typing
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { firstName: "", lastName: "", email: "" };

    if (!newUser.firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }

    if (!newUser.lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }

    if (!newUser.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUser.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleNewUserSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const newUserObject = {
          firstname: newUser.firstName,
          lastname: newUser.lastName,
          email: newUser.email,
          password: "firstlogin",
          location: ["Mumbai"],
          organization_id: assignmentConfig.organizationId,
          admin_token: localStorage.getItem("admin_token"),
        };

        const response = await createNewUser(newUserObject);

        // Assuming the API returns the created user's id and username
        const createdUser: SelectedUser = {
          userId: response.user_id,
          username: response.username,
        };

        setAssignmentConfig((prev) => ({
          ...prev,
          selectedUsers: [...prev.selectedUsers, createdUser],
        }));

        setNewUser({ firstName: "", lastName: "", email: "" });

        toast({
          title: "User Created",
          description: "New user has been successfully created and added to the assignment.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error creating new user:", error);
        toast({
          title: "Error",
          description: "Failed to create new user. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Form Error",
        description: "Please fill in all required fields correctly.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="start" width="100%">
      <FormControl isRequired width="100%">
        <FormLabel>Assign to Organization</FormLabel>
        <Select
          value={assignmentConfig.selectedOrganization}
          onChange={(e) => handleOrganizationChange(e.target.value, organizations.find((o) => o.name === e.target.value)?.id ?? 0)}
        >
          <option value="">Select an organization</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.name}>
              {org.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl as="fieldset" isRequired width="100%">
        <FormLabel as="legend">Assign to Admin</FormLabel>
        <HStack spacing={12} className="pt-4 justify-center" width="100%">
          <Box
            border={userType === "existing" ? `2px solid ${colorSystem["textPurple"]}` : "none"}
            className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
            onClick={() => setUserType("existing")}
          >
            <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
              👥
            </Box>
            <Box className="text-left p-4 h-[45%] rounded-b-xl" background="white" color="black" border="1px" borderColor="gray.200">
              <Heading size="md">Existing Admins</Heading>
              <Text className="text-slate-600">Assign to existing admins</Text>
            </Box>
          </Box>
          <Box
            border={userType === "new" ? `2px solid ${colorSystem["textPurple"]}` : "none"}
            className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
            onClick={() => setUserType("new")}
          >
            <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
              🌟
            </Box>
            <Box className="text-left p-4 h-[45%] rounded-b-xl" background="white" color="black" border="1px" borderColor="gray.200">
              <Heading size="md">New Admin</Heading>
              <Text className="text-slate-600">Create a new admin</Text>
            </Box>
          </Box>
        </HStack>
      </FormControl>

      {userType === "existing" && users.length > 0 && (
        <FormControl isRequired width="100%">
          <FormLabel>Assign to Existing User(s)</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width="100%">
              {assignmentConfig.selectedUsers.length > 0 ? `${assignmentConfig.selectedUsers.length} user(s) selected` : "Select users"}
            </MenuButton>
            <MenuList maxHeight="200px" overflowY="auto">
              {users.map((user) => (
                <MenuItem key={user.id}>
                  <Checkbox
                    isChecked={assignmentConfig.selectedUsers.some((selected) => selected.userId === user.id.toString())}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const selectedUser = { userId: user.id.toString(), username: user.name };
                      handleUserChange(
                        assignmentConfig.selectedUsers.some((selected) => selected.userId === user.id.toString())
                          ? assignmentConfig.selectedUsers.filter((selected) => selected.userId !== user.id.toString())
                          : [...assignmentConfig.selectedUsers, selectedUser]
                      );
                    }}
                  >
                    {user.name}
                  </Checkbox>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </FormControl>
      )}

      {userType === "new" && (
        <VStack spacing={4} align="stretch" width="100%">
          <FormControl isRequired isInvalid={!!formErrors.firstName}>
            <FormLabel>First Name</FormLabel>
            <Input placeholder="First Name" value={newUser.firstName} onChange={(e) => handleNewUserChange("firstName", e.target.value)} />
            <FormErrorMessage>{formErrors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formErrors.lastName}>
            <FormLabel>Last Name</FormLabel>
            <Input placeholder="Last Name" value={newUser.lastName} onChange={(e) => handleNewUserChange("lastName", e.target.value)} />
            <FormErrorMessage>{formErrors.lastName}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formErrors.email}>
            <FormLabel>Email ID</FormLabel>
            <Input placeholder="Email ID" type="email" value={newUser.email} onChange={(e) => handleNewUserChange("email", e.target.value)} />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
          <Button onClick={handleNewUserSubmit} colorScheme="blue" width="100%" isLoading={isLoading} loadingText="Creating User">
            Save New User
          </Button>
        </VStack>
      )}
    </VStack>
  );
};
export default Assignment;
