import React, { forwardRef} from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Stack,
  useDisclosure,
  ModalHeader,
  IconButton,
  BoxProps,
  Box,
  TextProps,
} from "@chakra-ui/react";
import Plyr from "plyr-react";
import { isUrl, getUrlType } from "../utils/helpers";
import { ViewIcon, CheckIcon, ExportIcon } from "../constants/icons";
import { adminloginUser } from "../api/auth";
import config from "../../src/config"

interface CandidateSummaryTextProps extends BoxProps {
  text: string;
  report: boolean;

  textProps?: TextProps;
}

const CandidateSummaryText = forwardRef<
  HTMLDivElement,
  CandidateSummaryTextProps
>(({ text, report, textProps, ...props }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalContentType = getUrlType(text);
  let token = localStorage.getItem("admin_token")
  function getFilenameFromContentDisposition(header) {
    const matches = /filename="([^"]*)"/.exec(header);
    return matches && matches[1] ? matches[1] : 'report.pdf';
  }
  const handleDownload = async () => {
    try {

      const response = await fetch(text, {
        method: 'GET',
        headers: {
          'Authorization': token, 
          'Accept': 'application/pdf'
        },

      });
      

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = getFilenameFromContentDisposition(contentDisposition);


      const a = document.createElement('a');
      a.href = url;
      a.download = filename; 
      a.click();


      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
    finally {
      let email = config.admin_email
      let password = config.admin_password
      let redirect = false
      let admin_data = await adminloginUser({ email, password, redirect }).catch(error => {
        console.log(error)
      })
      token = localStorage.getItem("admin_token")
      const response = await fetch(text, {
        method: 'GET',
        headers: {
          'Authorization': token, 
          'Accept': 'application/pdf'
        },

      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }else{
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = getFilenameFromContentDisposition(contentDisposition);


      const a = document.createElement('a');
      a.href = url;
      a.download = filename; 
      a.click();


      URL.revokeObjectURL(url);}
    }
  };

  return (
    <Box ref={ref} {...props}>
      {report ? (
          <Button
          onClick={handleDownload}
          variant="link"
          size="sm"
          color="pink.600"
          >
            <ExportIcon />
          </Button>
        ) : <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={Stack} direction="row" justify="space-between">
            <Text>
              {modalContentType === "video" ? "Watch video" : "View document"}
            </Text>
            <IconButton
              onClick={() => window.open(text)}
              size="sm"
              aria-label={"download"}
            >
              <ExportIcon size={12} />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            {modalContentType === "video" ? (
              <Plyr
                source={{
                  type: "video",
                  sources: [
                    {
                      src: text,
                      type: "video/mp4",
                    },
                  ],
                }}
                options={{
                  ratio: "4:3",
                }}
              />
            ) : (
              <embed className="w-full h-96 border-none" src={text} /> 
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isUrl(text) ? (
        <Button
          onClick={() => {
            console.log(`detected url: ${text}`);
            let type = getUrlType(text);
            console.log(type);
            type === "social" ? window.open(text) : onOpen();
          }}
          variant="link"
          size="sm"
          color="pink.600"
        >
          <ViewIcon />
        </Button>
      ) : (
        <Text
          style={{
            whiteSpace: "break-spaces",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
          }}
          {...textProps}
        >
          {text === "Yes" ? <CheckIcon color="green" /> : text}
        </Text>
      )}</>}
    </Box>
  );
});

export default CandidateSummaryText;
