import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getBotsData, transformBotsToUserRecords, BotRecords } from "../api/go_live";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  ButtonGroup,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
  Stack,
  Text,
  useBreakpointValue,
  Tag,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon, TagsIcon, DeleteIcon, AddIcon, ExportIcon, InfoIcon, SettingsIcon, EditIcon, DocumentIcon } from "../constants/icons";
import { Button } from "../components/Button";
import { CustomTable } from "./GoLiveSteps/CustomTable";
import { FlowExport } from "./FlowExport";
import { UserSummary } from "../views/UserProfile";
import GoLiveSteps from "./GoLiveSteps";
import NewBotConfig from "./GoLiveSteps/NewConfiguration";

const ConfirmationModal = ({ botName, flowId, source, onConfirm, onCancel }) => {
  return (
    <Modal isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Bot Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            This bot "{botName}" is already live on source "{source}" with flow ID "{flowId}".
          </Text>
          <Text>Do you want to view the details?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Yes
          </Button>
          <Button colorScheme="red" onClick={onCancel}>
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const GoLive = () => {
  const [records, setRecords] = useState<BotRecords[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showGoLiveSteps, setShowGoLiveSteps] = useState(false);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [showNewBotConfig, setShowNewBotConfig] = useState<boolean>(false);
  const [editableCell, setEditableCell] = useState<{ row: BotRecords; col: string }>({
    row: null,
    col: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [checkAllUsers, setCheckAllUsers] = useState<boolean>(false);
  const [editNote, seteditNote] = useState<boolean>(false);
  const [editStatus, seteditStatus] = useState<boolean>(false);
  const [currentUserProfile, setCurrentUserProfile] = useState<{
    userId: string;
    sessionId: string;
  } | null>(null);
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [exportMsgBetween, setexportMsgBetween] = useState<number[]>([]);
  const [msgFileName, setmsgFileName] = useState<string>("");
  const [selectedBot, setSelectedBot] = useState<BotRecords | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [showActive, setShowActive] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const fetchBots = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getBotsData();
      const bots = response.bots;
      const mappedRecords = transformBotsToUserRecords(bots);
      console.log("mappedRecords --> ", mappedRecords);
      setRecords(mappedRecords);
      setHasMore(mappedRecords.length > 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bots data:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBots();
  }, [fetchBots]);

  useEffect(() => {
    console.log("Records:", records);
    const idCounts = records.reduce((acc, record) => {
      acc[record.id] = (acc[record.id] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const duplicates = Object.entries(idCounts).filter(([_, count]) => count > 1);
    if (duplicates.length > 0) {
      console.error("Duplicate IDs found:", duplicates);
    }
  }, [records]);

  useEffect(() => {
    let activeCount = 0;
    let inactiveCount = 0;
    records.forEach((record) => {
      if (record.data?.["Status"] === "active") {
        activeCount++;
      } else {
        inactiveCount++;
      }
    });
    setActiveCount(activeCount);
    setInactiveCount(inactiveCount);
  }, [records]);

  const handleCellClick = (record: BotRecords, columnKey: string, data?: any) => {
    setEditableCell({ row: record, col: columnKey });
    if (columnKey === "Comment") {
      setSelectedRowId(record.id);
      seteditNote(true);
    } else if (columnKey === "Status") {
      setSelectedRowId(record.id);
      seteditStatus(true);
    }
  };

  // Function to filter records based on search query and active/inactive status
  const getFilteredRecords = () => {
    return records.filter((record) => {
      const matchesSearch =
        !searchQuery ||
        record.data["Bot Name"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        record.data["Flow Id"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        record.data["Type"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        record.data["Source"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        record.data["Status"].toLowerCase().includes(searchQuery.toLowerCase());

      // If neither checkbox is checked, show all records
      if (!showActive && !showInactive) {
        return matchesSearch;
      }

      // Otherwise, filter based on the checked boxes
      const matchesStatus = (showActive && record.data["Status"] === "active") || (showInactive && record.data["Status"] !== "active");

      return matchesSearch && matchesStatus;
    });
  };

  const filteredRecords = getFilteredRecords();

  const isSelectedBotActive = () => {
    if (selectedRowId) {
      const selectedRecord = records.find((record) => record.id === selectedRowId);
      return selectedRecord?.data?.["Status"] === "active";
    }
    return false;
  };

  // const handleGoLive = () => {
  //   if (selectedRowId) {
  //     const selectedRecord = records.find((record) => record.id === selectedRowId);
  //     if (selectedRecord && selectedRecord.data?.["Status"] !== "active") {
  //       navigate(`/golive/${selectedRecord.data["Flow Id"]}`);
  //     } else {
  //       console.log("Cannot go live with an active bot");
  //     }
  //   }
  // };

  // const handleViewDetails = () => {
  //   if (selectedRowId) {
  //     const selectedRecord = records.find((record) => record.id === selectedRowId);
  //     if (selectedRecord && selectedRecord.data?.["Status"] === "active") {
  //       navigate(`/golive/${selectedRecord.data["Flow Id"]}`);
  //     }
  //   }
  // };

  const handleConfigureNewBot = () => {
    setShowNewBotConfig(true);
  };

  const handleGoLive = () => {
    if (selectedRowId) {
      const selectedRecord = records.find((record) => record.id === selectedRowId);
      if (selectedRecord && selectedRecord.data?.["Status"] !== "active") {
        setSelectedBot(selectedRecord);
        setShowGoLiveSteps(true);
      } else {
        console.log("Cannot go live with an active bot");
      }
    }
  };

  const handleViewDetails = () => {
    if (selectedRowId) {
      const selectedRecord = records.find((record) => record.id === selectedRowId);
      if (selectedRecord && selectedRecord.data?.["Status"] === "active") {
        setSelectedBot(selectedRecord);
        setShowConfirmationModal(true);
      }
    }
  };

  const handleConfirmationModal = (confirm) => {
    setShowConfirmationModal(false);
    if (confirm) {
      setShowGoLiveSteps(true);
    } else {
      setSelectedBot(null);
    }
  };

  if (showNewBotConfig) {
    return <NewBotConfig onBack={() => setShowNewBotConfig(false)} />;
  }

  return (
    <Box h="100%" overflow="hidden">
      <Modal isOpen={showConfirmationModal} onClose={() => handleConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Edit Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to edit the configuration for "{selectedBot?.data["Bot Name"]}"?</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleConfirmationModal(true)}>
              Yes
            </Button>
            <Button onClick={() => handleConfirmationModal(false)}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showGoLiveSteps ? (
        <GoLiveSteps
          record={selectedBot}
          onBack={() => {
            setShowGoLiveSteps(false);
            setSelectedBot(null);
            fetchBots();
          }}
        />
      ) : (
        <Flex direction="column" h="100%" p={4}>
          <Flex mb={6} justify="space-between" align="center">
            <InputGroup size="sm" maxW="md">
              <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
              <Input
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
                placeholder="Search for Bot Name/Flow_id"
                bg="white !important"
              />
              <InputRightElement>{searchQuery.length !== 0 && <CloseIcon onClick={() => setSearchQuery("")} />}</InputRightElement>
            </InputGroup>

            <Flex align="center">
              <Flex align="center" mr={4}>
                <Checkbox isChecked={showActive} onChange={(e) => setShowActive(e.target.checked)} mr={2} />
                <Tag size="md" p={2} colorScheme="green">
                  Active: {activeCount}
                </Tag>
              </Flex>
              <Flex align="center" mr={4}>
                <Checkbox isChecked={showInactive} onChange={(e) => setShowInactive(e.target.checked)} mr={2} />
                <Tag size="md" p={2} colorScheme="red">
                  Not Active: {inactiveCount}
                </Tag>
              </Flex>
              <Tag size="md" p={2} mr={4} colorScheme="blue">
                Total: {records.length}
              </Tag>
              {/* <Button leftIcon={<SettingsIcon />} size="sm" onClick={handleConfigureNewBot}>
                Configure New Bot
              </Button> */}
            </Flex>
          </Flex>
          <Box flex={1} bg="white" borderRadius={8} boxShadow="md">
            <CustomTable
              records={getFilteredRecords()}
              hasMore={hasMore}
              loading={loading}
              editNote={editNote}
              editStatus={editStatus}
              editableCell={editableCell}
              fetchUser={fetchBots}
              onCellClick={handleCellClick}
              onNoteClose={() => {
                seteditNote(false);
                fetchBots();
              }}
              onStatusClose={() => {
                seteditStatus(false);
                fetchBots();
              }}
              selectedRowId={selectedRowId}
              onRowSelected={(selectedRow) => setSelectedRowId(selectedRow)}
              actions={
                <Stack direction="row" align="center" justify="space-between" p={3} hidden={!selectedRowId} bg="gray.50">
                  <Text fontSize="md" fontWeight="bold">
                    {selectedRowId ? "1 Bot selected" : "No Bot selected"}
                  </Text>
                  <Tooltip label={!selectedRowId ? "Select a bot" : ""}>
                    <ButtonGroup isDisabled={!selectedRowId}>
                      {isSelectedBotActive() ? (
                        <Button leftIcon={<EditIcon />} onClick={handleViewDetails}>
                          Edit Configuration
                        </Button>
                      ) : (
                        <Button leftIcon={<SettingsIcon />} onClick={handleGoLive}>
                          Set Configuration
                        </Button>
                      )}
                    </ButtonGroup>
                  </Tooltip>
                </Stack>
              }
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};
export default GoLive;
